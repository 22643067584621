import React, {useContext, useState} from 'react'
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {UseLoginForm} from "./Hooks/UseLoginForm";
import InputValidatorField from "../Inputs/InputValidatorField";
import {IsValidEmail, IsValidPassword} from "../../Utils/Validators";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {Tooltip} from "react-tooltip";
import {InputAdornment} from "@mui/material";
import {ExclamationMark} from "@phosphor-icons/react";
import {ThemeContext} from "styled-components";
import OTPInputField from "../Inputs/OTPInputField";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";



const ErrorMessage = ({ children }) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            color: '#d22727', // Error color
            background: '#faeeef', // Light background color for the error message
            padding: '10px',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)', // Subtle shadow
            mt: 2, // Margin top for spacing
        }}
    >
        <WarningAmberIcon sx={{ marginRight: '8px' }} /> {/* Error Icon */}
        <Typography>{children}</Typography>
    </Box>
);

const FormForgot = ({
    triggerReset,
        triggerForgotPasswordEmail,
}) => {
    const [formData, setFormData] = useState({
        email: '',
        verification_code: '',
        password: ''
    });
    const [showResetFields, setShowResetFields] = useState(false);
    const {
        handleSubmitForm,
        handleInputChange,
        allowSubmitForm,
        enableVerifyEmailNotification,
        enableInvalidCredentialsNotification,
    } = UseLoginForm();
    const theme = useContext(ThemeContext)

    return (
        <>
            <Box
                component="form"
                onSubmit={handleSubmitForm}
                sx={{
                    // position: 'absolute',
                    // left: '50%',
                    // top: '50%',
                    // transform: 'translate(-50%, -50%)',
                    // maxWidth: '500px',
                    margin: 'auto',
                    padding: '20px',
                    borderRadius: '8px',
                    height: '100',
                    flex: 1
                    // boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                }}
            >
                <InputValidatorField helperText="(Required)"
                                     label="Email"
                                     type="email"
                                     name="email"
                                     handleChange={handleInputChange}
                                     isValidInput={IsValidEmail}
                                     autoComplete="email"
                                     requirementsText="Valid email is required"
                                     InputLabelProps={{
                                         style: {color: 'white'},
                                     }}
                                     InputProps={{
                                         style: {color: 'white'},
                                     }}
                />
                <br/>
                <br/>
                {!showResetFields && (
                    <>
                        <Button type="submit"
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={() => {
                                    triggerReset();
                                    setShowResetFields(true);
                                }}
                                sx={{mt: 2, color: "primary", fontWeight: "bold", fontSize: "1.05em"}}>
                            Reset Password </Button>
                        <br />
                    </>
                )}
                {showResetFields && (
                    <>
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: 2 }}>
                            <OTPInputField
                                verificationCode={formData.verification_code}
                                handleChange={handleInputChange}
                                name="verification_code"
                                sx={{flexGrow: 1, mr: 1}}
                            />
                            {formData.verification_code.length < 6 && (
                                <Tooltip title="Resend verification code">
                                    <IconButton onClick={triggerForgotPasswordEmail} size="small">
                                        <RefreshIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                        <br />
                        <InputValidatorField helperText="(Required)"
                                             label="Password"
                                             type="password"
                                             name="password"
                                             handleChange={handleInputChange}
                                             isValidInput={IsValidPassword}
                                             requirementsText={["",
                                                 <div>
                                                     New password must include:
                                                     <br/>* 12 - 36 Characters
                                                     <br/>* Uppercase & Lowercase letters
                                                     <br/>* Numbers
                                                     <br/>* One special character: ^$*.[]{}()?-"!@#%&/\,':;|_~`+=
                                                 </div>
                                                 , ""]}
                                             autoComplete="current-password"
                                             InputLabelProps={{
                                                 style: {color: 'white'},
                                             }}
                                             InputProps={{
                                                 style: {color: 'white'},
                                             }}
                        />
                        <br />
                        <Button type="submit"
                                variant="contained"
                                color="secondary"
                                fullWidth
                                sx={{mt: 2, color: "primary", fontWeight: "bold", fontSize: "1.05em"}}>
                            Confirm </Button>
                        <br />
                        <br />
                    </>
                )}
            </Box>
        </>
    );
};
export default FormForgot;
