import ApiClient from "./ApiClient";
import NewUUID from "../Utils/Randy/NewUUID";

export const getLinkToken = async (csrfToken) => {
    // TODO : prevent if state is not Nevada
    const response = await ApiClient.get(`/api/banking/link`, {
        headers: {
            accept: "application/json",
            request_id: NewUUID(),
            "X-CSRF-Token": csrfToken
        },
    });
    return response.data.response;  // assuming the server response has the structure {data: {result: ...}}
};


export const linkAccount = async (csrfToken, publicToken, provider) => {
    // TODO : prevent if state is not Nevada
    try {
        const response = await ApiClient.post(
            `/api/banking/link`,
            {
                provider: provider,
                public_token: publicToken,
            },
            {
                headers: {
                    accept: 'application/json',
                    request_id: NewUUID(),
                    "X-CSRF-Token": csrfToken
                },
            }
        );
        return response.data.result;  // assuming the server response structure {data: {result: ...}}
    } catch (error) {
        console.error('Error linking account:', error);
        throw error; // Re-throw the error to allow the caller to handle it
    }
};

export const listLinkedAccounts = (csrfToken) => {
    // TODO : prevent if state is not Nevada
    return async () => {
        const response = await ApiClient.get(`/api/banking/`, {
            headers: {
                accept: "application/json",
                request_id: NewUUID(),
                "X-CSRF-Token": csrfToken
            },
        });
        return response.data.response
    }
}
