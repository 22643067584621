import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Box, Button, IconButton, Tab, Tabs, Tooltip} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import Unauthorized from "../Components/Layouts/Unauthorized";
import InputValidatorField from "../Components/Inputs/InputValidatorField";
import { IsValidEmail, IsValidPassword } from "../Utils/Validators";
import OTPInputField from "../Components/Inputs/OTPInputField";
import { AuthContext } from "../Providers/AuthProvider";
import { useHistory, useLocation } from "react-router-dom";
import { CsrfContext } from "../Providers/CsrfProvider";
import {sendResetPass, triggerResetPassToken} from "../api/Users";
import Typography from "@mui/material/Typography";
import FormReset from "../Components/Forms/FormReset";
import {ThemeContext} from "styled-components";
import FormLogin from "../Components/Forms/FormLogin";
import FormSignup from "../Components/Forms/FormSignup";
import FormForgot from "../Components/Forms/FormForgot";

const ResetPassPage = () => {
    const [formData, setFormData] = useState({ verification_code: '', email: '', password: '' });
    const [showResetFields, setShowResetFields] = useState(false);
    const { authClaims } = useContext(AuthContext);
    const history = useHistory();
    const location = useLocation();
    const { csrfToken } = useContext(CsrfContext);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const theme = useContext(ThemeContext);

    const [value, setValue] = useState(2);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const urlCode = new URLSearchParams(location.search).get('code');
        const urlEmail = new URLSearchParams(location.search).get('email');
        if (urlCode || urlEmail) {
            setFormData({
                verification_code: urlCode || '',
                email: decodeURIComponent(urlEmail) || '',
            });
        }
        if (urlEmail && urlCode) {
            setShowResetFields(true);
        } else if (urlEmail) {// Automatically show reset fields if URL contains email
            triggerForgotPasswordEmail()
        }
    }, [location.search]);

    const handleInputChange = (event) => {
        setFormData(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };


    const triggerReset = () => {
        if (IsValidEmail(formData.email)) {
            triggerResetPassToken(formData.email, csrfToken)
                .then(() => {
                    setShowResetFields(true);
                })
                .catch(err => {
                    console.error('Unable to trigger reset password:', err);
                });
        } else {
            console.log("Invalid email address.");
        }
    };


    const triggerForgotPasswordEmail = useCallback(() => {
        if (!IsValidEmail(formData.email)) {
            console.log("Invalid email address.");
            return;
        }

        if (isButtonDisabled) {
            console.log("Wait for cooldown");
            return;
        }

        setIsButtonDisabled(true);

        triggerResetPassToken(formData.email, csrfToken)
            .then(() => {
                setShowResetFields(true);
            })
            .catch(err => {
                console.error('Unable to trigger reset password:', err);
            })
            .finally(() => {
                setTimeout(() => {
                    setIsButtonDisabled(false);
                }, 10000); // Enable the button after 10 seconds
            });
    }, [formData.email, csrfToken, isButtonDisabled]);

    // const verifyCode = async () => {
    //     const { verification_code, email, password } = formData;
    //     if (verification_code !== undefined && verification_code.length === 6 && password !== undefined && password.length) {
    //         sendResetPass(email, password, verification_code, csrfToken)
    //             .then(response => {
    //                 // console.log("Result: ", response);
    //                 if (response.data.result === "" && response.data.error === "invalid verification code") {
    //                     console.log("Invalid verification code");
    //                 } else {
    //                     history.push(authClaims ? '/' : '/auth');
    //                 }
    //             })
    //             .catch(error => {
    //                 console.error('Error confirming email:', error);
    //             });
    //     }
    // };

    console.log("ResetPassPage")

    return (
        <Unauthorized>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
                backgroundColor: theme.colors.brandPrimary
            }}>
                <Box sx={{
                    position: 'relative',
                    width: 400, // Adjust as needed
                    height: 600, // Adjust as needed
                    boxShadow: 20,
                    borderRadius: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    color: theme.colors.textAlt
                }}>
                    <Tabs value={value} onChange={handleChange} aria-label="login signup tabs"
                          variant="fullWidth" // Makes tabs take up the full available width
                          indicatorColor="secondary" // Color of the tab indicator
                          textColor="inherit" // Color of the text in the tabs
                          sx={{
                              '.MuiTabs-flexContainer': {
                                  justifyContent: 'space-between', // Spreads out the tabs
                              },
                              '.MuiTab-root': {
                                  maxWidth: 'none',  // Allows tabs to take more space
                                  flex: 1,  // Each tab flexes to fill the space
                                  padding: '6px 12px', // Padding around text in each tab, adjust as needed
                                  margin: '0 8px', // Margin on the sides of each tab, adjust as needed
                                  '&.Mui-selected': {
                                      fontSize: '15px', // Font size when selected
                                      fontWeight: 'bold' // Optional: make text bold when selected
                                  }
                              }
                          }}
                    >
                        <Tab label="Login" />
                        <Tab label="Join" />
                        <Tab label="Forgot" />
                    </Tabs>
                    <Box sx={{ display:'flex', flexGrow: 1, p: 3 }}>
                        <Box sx={{ display:'flex', flexGrow: 1, p: 3 }}>
                            {value === 0 ? <FormLogin /> : value === 1 ? <FormSignup /> : <FormForgot triggerReset={triggerReset} triggerForgotPasswordEmail={triggerForgotPasswordEmail}/>}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Unauthorized>
    );
};

export default ResetPassPage;
